<script setup lang="ts">
const props = defineProps<{
  colorClass: string
  img?: {
    src: string
    alt: string
  }

  slides?: {
    image: string
    text: string
    url: string
    buttonText: string
  }[]
}>()
</script>

<template>
  <div class="relative flex flex-col items-center justify-center rounded-md md:flex-row" :class="props.colorClass">
    <div class="w-full flex justify-center md:w-1/2">
      <NuxtImg class="m-0 h-full w-full rounded-lb-md rounded-lt-md object-cover object-center" :src="props.img.src" :alt="props.img.alt" />
    </div>
    <div
      class="h-full w-full flex flex-col justify-between text-white md:w-1/2" :class="props.colorClass"
    >
      <slot name="content">
        <div class="p10 md:p20">
          <h1 class="text-4xl font-bold">
            Lorem ipsum dolor sit amet
          </h1>
          <p class="mt-4 text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec dui in nunc ultricies lacinia. Nullam
            nec dui in nunc ultricies lacinia. Nullam nec dui in nunc ultricies lacinia.
          </p>
        </div>
      </slot>
    </div>
  </div>
</template>
